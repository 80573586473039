import store from "../store";
import { cloneDeep, orderBy } from "lodash-es";
import { translate } from "../services/utils";

export default {
  install: (app, $store) => {
    app.config.globalProperties.$getTranslatable = (translatable, forcingLocale) => {
      const user = store.state.user;

      let locale = "en";
      if (forcingLocale && user?.locale) {
        locale = user.locale;
      } else if (!store.state.isLobby) {
        locale = window.navigator.language.substring(0, 2);
      } else if (user && user.locale) {
        locale = user.locale;
      }
      if (store.state.interfaceLanguage) {
        locale = store.state.interfaceLanguage;
      }
      if (!locale) {
        locale = "en";
      }
      const permittedLanguages =
        store.state.establishment && store.state.establishment.languages
          ? orderBy(store.state.establishment.languages, ["languageOrder"], ["asc"])
          : null;
      if (translatable) {
        if (permittedLanguages) {
          const localeIsPermitted = permittedLanguages.filter(language => {
            return language.languageCode === locale;
          });
          if (localeIsPermitted.length > 0 && translatable[locale]) {
            return translatable[locale];
          }
          for (let index = 0; index < permittedLanguages.length; index++) {
            const permittedLanguage = permittedLanguages[index];
            if (permittedLanguage.languageCode.length > 0 && translatable[permittedLanguage.languageCode]) {
              return translatable[permittedLanguage.languageCode];
            }
          }
          return " ";
        } else {
          if (translatable[locale]) {
            return translatable[locale];
          } else if (translatable["en"]) {
            return translatable["en"];
          } else {
            return Object.values(translatable)[0];
          }
        }
      } else {
        return " ";
      }
    };
    app.config.globalProperties.$getString = strKey => {
      const user = store.state.user;
      const str = store.state.strings?.find(string => string.strKey === strKey);

      if (!str) {
        return strKey;
      }

      const userCopy = cloneDeep(user);

      if (!userCopy.locale) {
        userCopy.locale = window.navigator.language.substring(0, 2);
      }
      if (store.state.interfaceLanguage) {
        userCopy.locale = store.state.interfaceLanguage;
      }

      const custom = str.custom;
      const customChain = str.customChain;
      const permittedLanguages = store.state.establishment?.languages ?? null;
      const permitted =
        permittedLanguages?.find(language => {
          return language.languageCode === userCopy.locale.split("_")[0];
        }) ?? false;
      const chain = store.state.establishment?.commercialEntity ?? 0;

      if (
        custom &&
        custom[userCopy.establishmentId] &&
        userCopy.locale &&
        custom[userCopy.establishmentId][userCopy.locale.split("_")[0]]
      ) {
        return translate(custom[userCopy.establishmentId], userCopy);
      }

      if (custom && custom[userCopy.establishmentId] && !permitted) {
        return translate(custom[userCopy.establishmentId], userCopy);
      }

      if (
        customChain &&
        chain &&
        customChain[chain] &&
        userCopy.locale &&
        customChain[chain][userCopy.locale.split("_")[0]]
      ) {
        return translate(customChain[chain], userCopy);
      }

      if (customChain && chain && customChain[chain] && !permitted) {
        const customTranslation = translate(customChain[chain], userCopy);
        if (customTranslation && customTranslation != "") {
          return translate(customChain[chain], userCopy);
        } else {
          return translate(str.strValues, userCopy);
        }
      }

      if (str.strValues) {
        return translate(str.strValues, userCopy);
      }
    };
  }
};
