import * as amplitude from "@amplitude/analytics-browser";
const BOOKING_SYSTEM_NEW_ON_DEMAND = 7;
const BOOKING_SYSTEM_SPACES = 11;
const BOOKING_ENABLED_CATALOG_MULTI_PRODUCT = 3;
const BOOKING_ENABLED_SPACE_WITH_ORDERING = 5;
const BOOKING_ENABLED_SPACE_ONLY_ORDERING = 6;
class AmplitudeUtils {
  static baseProperties(state) {
    const { user, establishmentHash, establishment } = state;

    const baseProperties = {
      environment: import.meta.env.VITE_VUE_APP_ENVIRONMENT,
      host: window.location.host,
      hotelId: establishmentHash,
      hotelName: establishment.name,
      isGuest: !!user.cliId,
      stayUserId: user.cliId,
      chainId: establishment.topCommercialEntity,
      brandId: establishment.commercialEntity
    };

    return baseProperties;
  }

  static formatKeys(properties) {
    Object.entries(properties).forEach(([key, value]) => {
      const newKey = key.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase());
      properties[newKey] = value;
      delete properties[key];
    });

    return properties;
  }
}

export default {
  install: (app, { apiKey, configuration }, $store) => {
    amplitude.init(apiKey, configuration);

    app.config.globalProperties.$amplitude = () => amplitude;
    app.config.globalProperties.$amplitude.track = (event_type, properties) => {
      const customProperties = { ...AmplitudeUtils.baseProperties($store.state), ...properties };
      if (
        ![
          BOOKING_ENABLED_CATALOG_MULTI_PRODUCT,
          BOOKING_ENABLED_SPACE_WITH_ORDERING,
          BOOKING_ENABLED_SPACE_ONLY_ORDERING
        ].includes(customProperties.bookingEnabled) ||
        ![BOOKING_SYSTEM_SPACES].includes(customProperties.bookingSystem)
      ) {
        return;
      }

      amplitude.track({
        event_type,
        event_properties: AmplitudeUtils.formatKeys(customProperties),
        user_id: $store.state.user.cliId
      });
    };

    app.config.globalProperties.$amplitude.events = {
      funnel: {
        productListViewed: "Product List Viewed",
        productDetailViewed: "Product Detail Viewed",
        productDetailClosed: "Product Detail Closed",
        productAddonsViewed: "Product Addons Viewed",
        productAddonsSelected: "Addon Selected",
        productAddedToCart: "Product Added",
        productAmountChanged: "Product Amount Changed",
        nextStep: "Next Step",
        cartViewed: "Cart Viewed",
        exitModalShowed: "Funnel Exit modal",
        exitModalCancelOrder: "Funnel Exit Cancel Order",
        exitModalContinueOrder: "Funnel Exit Continue Order",
        visitorOrUserViewed: "Login Viewed",
        visitorOrUserSelected: "User type Selected",
        placeOfDeliveryViewed: "Place of Delivery Viewed",
        placeOfDeliverySelected: "Place of Delivery Selected",
        paymentViewed: "Payment Method Viewed",
        paymentMethodSelected: "Payment Method Selected",
        paymentResultViewed: "Payment Result Viewed",
        ordersViewed: "Orders Viewed",
        ordersConfirmedPopupViewed: "Order Confirmed Popup Viewed",
        ordersConfirmedPopupClosed: "Order Confirmed Popup Closed",
        deliveryDateTimeViewed: "Date and Time of Delivery Viewed",
        commentsViewed: "Comments Viewed",
        guestLoginViewed: "Guest Login Viewed",
        transactionConfirmedViewed: "Transaction Confirmed Viewed",
        multipleOrderCatalogsViewed: "Multiple Order Catalogs Viewed"
      }
    };
  }
};
