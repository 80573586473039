<template>
  <div v-show="!isHidden" class="c-notifications-popup animated fadeIn">
    <div v-if="!isHidden" class="c-notifications-popup__card">
      <div class="c-notifications-popup__card__image">
        <component :is="bellIcon" />
      </div>

      <div class="c-notifications-popup__card__container">
        <div class="c-notifications-popup__card__texts">
          <p class="c-notifications-popup__card__texts__title">
            {{ getString("ALLOW_NOTIFICATIONS_TITLE") }}
          </p>

          <p class="c-notifications-popup__card__texts__info">
            {{ getString("ALLOW_NOTIFICATIONS_TEXT") }}
          </p>
        </div>

        <button class="c-notifications-popup__card__accept-button" @click="grantPermission">
          {{ getString("ALLOW_NOTIFICATIONS_OK") }}
        </button>
        <p class="c-notifications-popup__card__denied-button" @click="isHidden = true">
          {{ getString("ALLOW_NOTIFICATIONS_NOT_THANKS") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { checkNotifications, showFirstPushPermission } from "../../../serviceWorker";
import moment from "moment-timezone";
import { getString, isEmbedPWA } from "../../../services/utils";
import { mapState } from "vuex";
import bellIconSVG from "../../../assets/icons/bell-icon.svg";
import { markRaw } from "vue";

export default {
  name: "NotificationsPopup",
  data() {
    return {
      isHidden: true,
      minutes: 1
    };
  },
  computed: {
    ...mapState(["strings", "user"]),
    ...mapState("notificationsModule", ["timestampPopup", "isGranted"]),
    bellIcon() {
      return markRaw(bellIconSVG);
    },
    isIOS() {
      return /iphone|ipod|ipad/i.test(window.navigator.userAgent);
    },
    isLobby() {
      return this.$store.state.isLobby;
    },
    notificationAvailable() {
      return "Notification" in window;
    },
    notificationPermission: {
      get() {
        return this.isGranted;
      },
      set(newValue) {
        this.$store.commit("notificationsModule/setNotificationPermission", newValue);
      }
    }
  },
  async created() {
    if (!this.notificationAvailable) return;

    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        this.showModal();
      }
    });

    document.addEventListener("click", async () => {
      try {
        if (this.isIOS && this.notificationAvailable) {
          this.notificationPermission = await Notification?.requestPermission();
        }

        this.showModal();
      } catch (error) {
        console.error(error);
      }
    });

    this.showModal();
  },
  methods: {
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    diffLastPopup() {
      return moment().diff(this.timestampPopup, "minutes");
    },
    checkPermissionsAndRegister() {
      if (this.timestampPopup == null) {
        return false;
      }

      if (Notification.permission !== "granted" && this.diffLastPopup() >= this.minutes) {
        return false;
      }

      if (!this.isIOS) {
        this.notificationPermission = Notification.permission;
      }

      if (Notification.permission === "granted") {
        showFirstPushPermission(this.$store.state);
      }

      return true;
    },
    async showModal() {
      if (isEmbedPWA() || this.isLobby) return;

      if (this.isIOS && this.notificationPermission === "granted") return;

      if (this.checkPermissionsAndRegister()) return;

      if (!window.matchMedia("(display-mode: standalone)").matches && this.isIOS) return;

      if (this.timestampPopup !== null) return;

      if (this.timestampPopup === null || this.diffLastPopup() >= this.minutes) {
        this.$store.commit("notificationsModule/setTimestampPopup", moment());
      }

      if (!this.isIOS) {
        this.notificationPermission = Notification.permission;
      }

      this.isHidden = false;
    },
    async grantPermission() {
      try {
        this.isHidden = true;
        await checkNotifications(this.$store);
        this.$store.dispatch("createVisitor");
      } catch (error) {
        console.error(error);
      } finally {
        this.isHidden = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide";

* {
  box-sizing: border-box;
}

.c-notifications-popup {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 20000;

  background: rgba(0, 0, 0, 0.7);

  &__card {
    width: 100%;
    max-width: 312px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: @size-base*4;

    padding: @size-base*6 @size-base*6 @size-base*8;

    background-color: @color-bg-page;
    border-radius: @size-base-grid*2;

    &__image {
      width: 68px;
      height: 68px;
      color: @color-icons;
    }

    &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: @size-base*6;
    }

    &__texts {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: @size-base*2;

      &__title {
        .u-text__headline--4;
        text-align: center;
        margin: 0;
      }

      &__info {
        .u-text__caption;
        text-align: center;
        margin: 0;
      }
    }

    &__accept-button {
      .u-button__primary;
    }
    &__denied-button {
      .u-text__paragraph--grey;
      margin: 0;
    }
  }
}
</style>
