<template>
  <div class="o-app-layout">
    <div v-if="isRedirect" class="loader-content">
      <div class="loader-front"></div>
      <h1 class="header-front">Loading...</h1>
    </div>

    <main>
      <transition-group
        v-if="isNotificationVisible || isSurveyVisible || isNotificationMultipleVisible"
        key="transition-group__key"
        name="slideIn"
        tag="div"
      >
        <div
          v-if="isNotificationVisible"
          key="is-notification-visible__key"
          class="notifications"
          :class="[isDesktop ? 'desktop animated slideInRight' : 'animated slideInDown']"
          leave-active-class="animated fadeOut"
        >
          <notification
            :notification="notShownNotifications[0]"
            @open-notification="openNotification"
            @close-notification="closeNotification"
          ></notification>
        </div>

        <div
          v-else-if="isSurveyVisible"
          key="survey-visible__key"
          class="display-surveys animated fadeIn"
          leave-active-class="animated fadeOut"
        >
          <display-survey
            :survey-data="notShownNotifications[0]"
            @open-notification="openNotification"
            @close-notification="closeNotification"
          >
          </display-survey>
        </div>

        <div
          v-else-if="isNotificationMultipleVisible"
          key="multiple-notification__key"
          class="notifications"
          :class="[isDesktop ? 'desktop animated slideInRight' : 'animated slideInDown']"
          leave-active-class="animated fadeOut"
        >
          <notification
            :notification="multipleNotifications"
            @open-multiple-notifications="openMultipleNotifications"
            @close-notification="dismissAllNotifications"
          ></notification>
        </div>
      </transition-group>

      <div
        v-if="verticalScreen"
        key="vertical-screen__key"
        class="vertical-screen-top-img"
        :class="{ 'vertical-screen-top-img--video': verticalScreenWithVideo }"
        :style="getVerticalLobbyImageURL(data.lobbyTopImage)"
      >
        <div v-if="verticalScreenWithVideo" class="video-background">
          <video type="video/mp4" loop muted playsinline autoplay>
            <source :src="data.lobbyVideoURL" type="video/mp4" />
          </video>
        </div>
      </div>

      <div
        key="router-view__key"
        class="router-view-wrapper"
        :class="{
          'vertical-screen': verticalScreen,
          'vertical-screen--video': verticalScreenWithVideo,
          landscape: isDesktop || isLobby,
        }"
      >
        <div class="loader-content">
          <div class="loader-front"></div>
          <h1 class="header-front">Loading...</h1>
        </div>
        <div class="o-router-view__content">
          <router-view v-if="isLoaded" ref="view" v-slot="{ Component }">
            <transition v-if="transitionAnimation" :name="transitionAnimation">
              <component :is="Component" />
            </transition>
            <component :is="Component" v-else />
          </router-view>
        </div>
      </div>

      <div
        v-if="verticalScreen && !verticalScreenWithVideo"
        key="vertical-screen-bottom-img__key"
        class="vertical-screen-bottom-img"
        :style="getVerticalLobbyImageURL(data.lobbyBottomImage)"
      ></div>

      <div
        v-show="
          banner &&
          banner.active &&
          !getStatuswelcomeShowVisible(welcomeVisibleState, notifications, hideNotifications) &&
          !isEmbedOrFullPWA
        "
        key="notifiactions__key"
        class="notifications"
      >
        <transition-group name="slideIn" tag="div">
          <div key="native-banner__key" class="animated slideInDown" leave-active-class="animated fadeOut">
            <native-banner
              v-if="!isDesktop && !isTV && !isLobby"
              :banner="banner"
              @open-banner-link="openBannerLink"
              @close-banner="closeBanner"
            ></native-banner>
          </div>
        </transition-group>
      </div>

      <div key="others__key">
        <enquiries v-if="showEnquiries"></enquiries>

        <surveys v-if="surveysVersion === 2" :survey-data="surveyData"></surveys>
        <survey-funnel v-else-if="surveysVersion >= 3" :survey-data="surveyData"></survey-funnel>

        <message-popup
          v-if="subscriptionInactive"
          style="height: 100%"
          :title="getString('INFORMATION')"
          :text="getString('SUBSCRIPTION_INACTIVE')"
          :action-text="getString('CHECK_CODE')"
          :input-text="getString('INSERT_ACTIVATION_CODE')"
          @click="unlockAppWithCode"
        ></message-popup>
        <show-upsell-notification></show-upsell-notification>

        <notifications-popup v-if="!!$store.state.user.userKey"></notifications-popup>
        <message-popup
          v-if="showError"
          style="height: 100%"
          :title="getString('ERROR')"
          :text="errorMessage"
          :action-text="getString('CLOSE')"
          @click="showError = false"
        />
      </div>
    </main>
  </div>
</template>

<script>
import { startSubscription } from "./serviceWorker";
import {
  couldBeNativeEmbeddedPWA,
  isEmbeddedPWAByAppId,
  deviceDetector,
  getImageUrl,
  getStatuswelcomeShowVisible,
  getString,
  isEmbedPWA,
  isFullEmbedPWA,
  isUrlTokenAppId,
  translate,
  openExternalURL,
  previewLegalClause,
} from "./services/utils";
import config from "./config";
import appNotificationsMixin from "./mixins/appNotificationsMixin";
import { EventBus } from "./services/eventBus";
import deepLinks from "@/mixins/deepLinks/deepLinks";
import { defineAsyncComponent, shallowRef } from "vue";
import NotificationsPopup from "./components/Notifications/NotificationsPopup/NotificationsPopup.vue";
import * as types from "@/store/mutation-types";

export default {
  name: "App",
  components: {
    nativeBanner: defineAsyncComponent(() => import("./components/NativeBanner.vue")),
    notification: defineAsyncComponent(() => import("./components/Notification.vue")),
    enquiries: defineAsyncComponent(() => import("./components/Enquiries/Enquiries.vue")),
    surveys: defineAsyncComponent(() => import("./components/Surveys/SurveysV2.vue")),
    surveyFunnel: defineAsyncComponent(() => import("./components/SurveysV3/SurveyFunnel.vue")),
    messagePopup: defineAsyncComponent(() => import("./components/MessagePopup.vue")),
    displaySurvey: defineAsyncComponent(() => import("./components/SurveysV3/DisplaySurvey.vue")),
    showUpsellNotification: defineAsyncComponent(() => import("./components/Promotions/PromotionsScreen.vue")),
    NotificationsPopup,
  },
  mixins: [appNotificationsMixin, deepLinks],
  setup() {
    return {
      frontpageFactory: shallowRef(defineAsyncComponent(() => import("@/components/FrontpageFactory.vue"))),
    };
  },
  data() {
    return {
      enterActiveClass: "animated fadeIn",
      leaveActiveClass: "animated fadeOut",
      mode: "out-in",
      duration: { enter: 300, leave: 300 },
      isRedirect: false,
      showEnquiries: false,
      subscriptionInactive: false,
      surveyData: false,
      surveysVersion: undefined,
      banner: null,
      bannerShown: false,
      multipleNotifications: {},
      currentRoutePath: "/",
      showDisplaySurveys: false,
      originalShowDisplaySurveysValue: false,
      isUpsellDisplaying: false,
      visibleComponentFrontpage: false,
      isWelcomePopupDisplaying: false,
      transitionAnimation: "slide-left",
      startedSwipe: false,
      continuedSwipe: false,
      showError: false,
      errorMessage: "",
    };
  },
  computed: {
    isEmbedOrFullPWA: function () {
      return isEmbedPWA() || isFullEmbedPWA();
    },
    data: function () {
      return this.$store.state.frontpage.data;
    },
    clusterEstablishmentId: function () {
      return this.$store.state.clusterEstablishmentId;
    },
    botToken: function () {
      return this.$store.state.botToken;
    },
    cmsToken: function () {
      return this.$store.state.cmsToken;
    },
    notifications: function () {
      return this.$store.state.notifications;
    },
    isDesktop: function () {
      return this.$store.state.isDesktop;
    },
    isTV: function () {
      return this.$store.state.isTV;
    },
    isLobby: function () {
      return this.$store.state.isLobby;
    },
    welcomeVisibleState: function () {
      return this.$store.state.welcomeShowVisible[this.establishmentHash];
    },
    establishmentHash: function () {
      return this.$store.state.establishmentHash;
    },
    strings() {
      return this.$store.state.strings;
    },
    user: function () {
      return this.$store.state.user;
    },
    allSurveys: function () {
      return this.$store.state.allSurveys;
    },
    verticalScreen: function () {
      return this.$store.state.verticalScreen;
    },
    verticalScreenWithVideo: function () {
      return this.$store.state.verticalScreenWithVideo;
    },
    notShownNotifications() {
      this.setDisplaySurvey();

      if (!this.notifications || this.notifications.length === 0) {
        return [];
      }

      const filtered = this.notifications.filter(({ alreadyShown }) => !alreadyShown);
      const filteredSurvey = filtered.find(({ type }) => type === "survey");

      if (filtered.length <= 1 || this.surveysVersion !== 3 || !filteredSurvey) {
        return filtered;
      }

      return [filteredSurvey];
    },
    lobbyHasTopAndBottomImages() {
      return this.data.lobbyTopImage && this.data.lobbyBottomImage;
    },
    lobbyHasVideo() {
      return this.data.lobbyHasVideo && this.data.lobbyVideoURL && this.data.lobbyVideoURL !== "";
    },
    notificationsNowVisible() {
      const hasNotificationsToShow = !!this.notShownNotifications.length;

      return (
        !this.hideNotifications &&
        hasNotificationsToShow &&
        !this.getStatuswelcomeShowVisible(
          this.welcomeVisibleState,
          this.notShownNotifications,
          this.hideNotifications,
        ) &&
        this.currentRoutePath !== "/inbox" &&
        this.currentRoutePath !== "/survey-chat"
      );
    },
    isNotificationVisible() {
      return (
        this.notificationsNowVisible &&
        this.notShownNotifications.length === 1 &&
        (this.notShownNotifications[0]?.type !== "survey" ||
          (this.notShownNotifications[0]?.type === "survey" && this.surveysVersion < 3)) &&
        !this.isLobby
      );
    },
    isSurveyVisible() {
      return (
        this.surveysVersion >= 3 &&
        this.showDisplaySurveys &&
        this.notificationsNowVisible &&
        this.notShownNotifications.length === 1 &&
        this.notShownNotifications[0]?.type === "survey" &&
        this.currentRoutePath === "/" &&
        !this.visibleComponentFrontpage &&
        !this.isUpsellDisplaying &&
        !this.isWelcomePopupDisplaying &&
        !this.isLobby &&
        this.isLoaded &&
        this.$route.name === "Frontpage"
      );
    },
    isNotificationMultipleVisible() {
      return this.notificationsNowVisible && this.notShownNotifications.length > 1 && !this.isLobby;
    },
    showSafeAreas() {
      return isFullEmbedPWA() && couldBeNativeEmbeddedPWA(this.$store.state.appId);
    },
  },
  watch: {
    $route(to, from) {
      this.manageTransition(to, from);
      this.banner = null;
      this.currentRoutePath = to.path;

      if (this.isRedirect && to.path.includes("redirect")) {
        setTimeout(() => (this.isRedirect = false), 400);
      }

      this.duration.enter = 200;
      this.duration.leave = 200;

      if (to.path.includes("/chat") && from.path === "/" && !to.params.userKey) {
        this.enterActiveClass = "animated fadeIn";
        this.leaveActiveClass = "";
        this.mode = "in-out";
        this.duration.enter = 600;
      } else if (to.path.includes("/enquiries-list") && from.path === "/") {
        this.enterActiveClass = "animated slideInRight";
        this.leaveActiveClass = "animated slideOutLeft";
        this.mode = "in-out";
        this.duration.enter = 300;
      } else {
        this.enterActiveClass = "animated fadeIn";
        this.leaveActiveClass = "animated fadeOut";
        this.mode = "out-in";
      }
    },
    data() {
      if (!this.bannerShown && this.data && this.data.banner) {
        this.banner = this.data.banner;
        this.bannerShown = true;
      }

      this.checkForVerticalScreen();
    },
    isLobby(newValue, oldValue) {
      this.checkForVerticalScreen();
    },
    showSafeAreas: {
      handler() {
        this.$store.commit("EMBED_SAFEAREA", 0);
        if (!this.showSafeAreas) return;

        this.$store.commit("EMBED_SAFEAREA", 28);
        document.documentElement.style.setProperty("--embed-top-safearea", "28px");
        document.documentElement.style.setProperty("--embed-bottom-safearea", "22px");
      },
      immediate: true,
    },
  },
  created() {
    navigator?.serviceWorker?.addEventListener("message", (event) => {
      if (!event?.data?.action) {
        return;
      }

      switch (event.data.action) {
        case "redirect-from-notificationclick":
          window.location.href = event.data.url;
          break;
      }
    });
    let image = this.data?.backgroundImage;
    if (this.data?.headerWidget?.backgroundImage) {
      image = this.data.headerWidget.backgroundImage;
    }
    this.multipleNotifications = {
      type: "multiple",
      data: {
        id: "multiple",
        image: image,
        title: this.getString("YOU_HAVE_MULTIPLE_NOTIFICATIONS"),
      },
    };
    this.checkPreviewParam();

    const searchParams = window.location.search.replace("?", "").split("&");
    const queryParams = {};
    searchParams.forEach((par) => {
      const keyValue = par.split("=");
      if (keyValue.length === 2) {
        if (keyValue[0] === "utm_source") {
          this.$store.commit("UTM_SOURCE", keyValue[1]);
        }
        if (keyValue[0] === "utm_campaign") {
          this.$store.commit("UTM_CAMPAIGN", keyValue[1]);
        }
        if (keyValue[0] === "utm_medium") {
          this.$store.commit("UTM_MEDIUM", keyValue[1]);
        }
      }
    });
    if (window.location.href.indexOf("/redirect?urlToken=") !== -1) {
      this.isRedirect = true;
    }
    this.$store.commit("STORE_FRONTPAGE_SCROLL", 0);
    addEventListener("messageAdded", this.messageAdded);
    addEventListener("guestInvited", this.guestInvited);
    addEventListener("userLoggedIn", this.userLoggedIn);
    addEventListener("userLoggedOut", this.userLoggedOut);
    addEventListener("initChatClientEvent", this.initChatClientEvent);
    addEventListener("enquiriesEnabled", this.enquiriesEnabled);
    addEventListener("updateSurveysVersion", this.updateSurveysVersion);

    EventBus.$on("uploadNotification", () => {
      this.showDisplaySurveys = false;
    });
    EventBus.$off("visibleComponentFrontpage");
    EventBus.$on("visibleComponentFrontpage", (visible) => {
      this.visibleComponentFrontpage = visible;
    });

    EventBus.$on("startingSubscription", () => {
      this.startPushSubscription();
    });
    EventBus.$on("checkSubscription", () => {
      this.checkSubscription();
    });
    EventBus.$off("surveyButtonAction");
    EventBus.$on("surveyButtonAction", this.getSurveyData);
    EventBus.$off("accessLinkUserCollision");
    EventBus.$on("accessLinkUserCollision", this.accessLinkUserCollision);
    if (this.data && this.data.widgetActive) {
      //if the user intialized some time a chat client, its saved in the store and if create it here again, the user will be the same than before
      if (this.$store.state.userUsedChat) {
        this.initChatClient(false);
      }
    } else {
      addEventListener("frontpageDownloaded", this.frontpageDownloaded);
    }
    if (!config.botApiCredentials.bearer && this.botToken) {
      config.botApiCredentials.bearer = this.botToken;
    }
    if (!config.cmsApiHostCredentials.bearer && this.cmsToken) {
      config.cmsApiHostCredentials.bearer = this.cmsToken;
    }
    const self = this;
    document.addEventListener(
      "touchstart",
      function () {
        self.continuedSwipe = false;
        self.startedSwipe = true;
      },
      false,
    );
    document.addEventListener(
      "touchmove",
      function () {
        self.continuedSwipe = true;
      },
      false,
    );

    if (this.$store.state && this.$store.state.mainColor) {
      document.getElementsByTagName("html")[0].style.setProperty("--main-color", this.$store.state.mainColor);
      document.getElementsByTagName("html")[0].style.setProperty("--cc-btn-primary-bg", this.$store.state.mainColor);
    }
    if (
      this.$store.state &&
      this.$store.state.establishment &&
      this.$store.state.establishment.requestChat &&
      this.$store.state.establishment.requestChat.allowed
    ) {
      this.showEnquiries = true;
    }

    if (this.$store.state?.establishment?.surveysVersion) {
      this.surveysVersion = this.$store.state.establishment.surveysVersion;
    }

    if (window.location.search.indexOf("mode=cms") !== -1) {
      this.$store.commit("IS_DESKTOP", false);
      this.$store.commit("IS_TV", false);
      this.$store.commit("IS_LOBBY", false);
      this.$store.commit("CMS_MODE", true);
      this.$store.commit(types.INTERFACE_LANGUAGE, "en");
      this.$store.commit(types.SET_USER_LOCALE, "en");
    } else if (window.location.href.includes("/tv")) {
      // TV
      this.$store.commit("IS_TV", true);
      this.$store.commit("IS_LOBBY", false);
      this.$store.commit("IS_DESKTOP", true);
      this.$store.commit("CMS_MODE", false);
    } else if (window.location.href.includes("/lobby")) {
      // LOBBY
      this.$store.commit("IS_TV", false);
      this.$store.commit("IS_LOBBY", true);
      this.$store.commit("IS_DESKTOP", false);
      this.$store.commit("CMS_MODE", false);
    } else if (!isEmbedPWA() && (deviceDetector.device == "desktop" || deviceDetector.device == "tablet")) {
      // In desktop or tablet redirect to /desktop (if the current url is not in /desktop or /lobby yet)
      // DESKTOP
      this.$store.commit("IS_DESKTOP", true);
      this.$store.commit("IS_TV", false);
      this.$store.commit("IS_LOBBY", false);
      this.$store.commit("CMS_MODE", false);
      if (
        !window.location.href.includes("/desktop") &&
        !window.location.href.includes("/lobby") &&
        !window.location.href.includes("/tv")
      ) {
        window.location.pathname = "/desktop" + window.location.pathname;
      }
    } else {
      this.$store.commit("IS_DESKTOP", false);
      this.$store.commit("CMS_MODE", false);
    }

    if (!isEmbedPWA() && window.location.href.includes("/desktop") && deviceDetector.device == "phone") {
      // URL is desktop but the user is in mobile (redirect to mobile url)
      this.$store.commit("IS_DESKTOP", false);
      this.$store.commit("IS_TV", false);
      this.$store.commit("IS_LOBBY", false);

      window.location.pathname = window.location.pathname.replace("/desktop", "");
    }

    if (this.isLobby) {
      this.checkForVerticalScreen();
    }

    this.checkSubscription();
    if (this.$store.state.strings.length === 0) {
      EventBus.$off("stringsDownloaded");
      EventBus.$on("stringsDownloaded", () => {
        this.checkLegalTexts();
      });
    } else {
      this.checkLegalTexts();
    }
    EventBus.$off("showCookieSettings");
    EventBus.$on("showCookieSettings", () => {
      this.showCookieSettings();
    });
    EventBus.$off("getUserActivityAndInbox");
    EventBus.$on("getUserActivityAndInbox", () => {
      this.getUserActivityAndInbox();
    });
    document.addEventListener("visibilitychange", () => {
      const isEmbeddedPWA = isEmbedPWA() || isFullEmbedPWA();
      if (!isEmbeddedPWA && document.visibilityState === "visible") {
        this.getUserActivityAndInbox();
      }
    });
    if (window.location.pathname !== "/") {
      this.getUserActivityAndInbox();
    }
  },
  mounted() {
    EventBus.$on("displayUpsellPopup", this.onDisplayUpsellPopup);
    EventBus.$on("displayWelcomePopup", this.onDisplayWelcomePopup);
  },
  updated() {
    if (this.$refs?.view?.$el && document) {
      setTimeout(() => {
        document?.querySelectorAll(".loader-content").forEach((it) => {
          try {
            if (it || this.$refs?.view?.$el.querySelector("*")) {
              it.style.display = "none";
            }
          } catch (e) {
            console.error(e);
          }
        });
      }, 300);
    }
  },
  unmounted() {
    removeEventListener("messageAdded", this.messageAdded);
    removeEventListener("guestInvited", this.guestInvited);
    removeEventListener("userLoggedIn", this.userLoggedIn);
    removeEventListener("userLoggedOut", this.userLoggedOut);
    removeEventListener("initChatClientEvent", this.initChatClientEvent);
    removeEventListener("enquiriesEnabled", this.enquiriesEnabled);
    removeEventListener("updateSurveysVersion", this.updateSurveysVersion);
  },
  methods: {
    translate,
    getImageUrl,
    onDisplayUpsellPopup(isUpsellDisplaying) {
      this.isUpsellDisplaying = isUpsellDisplaying;

      if (this.showDisplaySurveys && isUpsellDisplaying) {
        this.showDisplaySurveys = false;
      } else {
        this.showDisplaySurveys = true;
      }
    },
    onDisplayWelcomePopup(isWelcomePopupDisplaying) {
      this.isWelcomePopupDisplaying = isWelcomePopupDisplaying;
      this.showDisplaySurveys = false;
    },
    getStatuswelcomeShowVisible,
    openBannerLink(link) {
      openExternalURL(link);
      this.banner = null;
    },
    closeBanner() {
      this.banner = null;
    },
    async startPushSubscription() {
      try {
        await startSubscription(this.$store.state);
      } catch (error) {
        console.error("INDEXPWA Error storing subscription in App.vue", error);
      }
    },
    initChatClientEvent() {
      //from event
      if (this.data && this.data.widgetActive) {
        this.initChatClient(true);
      }
    },
    frontpageDownloaded() {
      if (this.data && this.data.widgetActive) {
        this.initChatClient();
      }
      if (this.data && this.data.banner) {
        this.banner = this.data.banner;
      }
      removeEventListener("frontpageDownloaded", this.frontpageDownloaded);
    },
    enquiriesEnabled() {
      this.showEnquiries = true;
    },
    updateSurveysVersion(event) {
      this.surveysVersion = event.detail;
    },
    getString(key) {
      return getString(this.strings, key, this.user);
    },
    async checkSubscription() {
      if (!this.establishment?.id) {
        return;
      }

      try {
        const { active } = await this.$store.dispatch("subscriptionActive", this.establishment.id);

        this.subscriptionInactive = !active;

        if (this.subscriptionInactive) {
          EventBus.$emit("subscriptionInactive");
        }
      } catch (error) {
        console.error("Error checking subscription", error);
        this.subscriptionInactive = false;
      }
    },
    unlockAppWithCode(object) {
      this.$store
        .dispatch("unlockAppWithCode", { establishmentId: this.establishment.id, code: object.code })
        .then((response) => {
          if (response.validCode === true) {
            this.subscriptionInactive = false;
          }
        })
        .catch((error) => {
          console.error("Error unlocking", error);
          //this.subscriptionInactive = false;
        });
    },
    getSurveyData(object) {
      if (object && object.surveyData) {
        object.surveyData.trigger = object.triggerType;
        object.surveyData.serviceData = object.service;
        object.surveyData.serviceId = object.service ? object.service.id : object.serviceId;
        object.surveyData.booking = object.booking;
        object.surveyData.request = object.request;
        this.surveyData = object.surveyData;
        return;
      }
      const itemType = object.itemType;
      const itemId = object.itemId;
      const triggerType = object.triggerType;
      const found = Object.values(this.allSurveys).find((survey) => {
        return survey.itemType === itemType && survey.itemId === itemId && survey.triggerType === triggerType;
      });

      if (!found) {
        this.surveyLoading = false;
        return;
      }

      this.$store
        .dispatch("getSurvey", found.id)
        .then((response) => (this.surveyData = response))
        .catch((error) => console.error("Error getting quetsions", error));
    },
    checkPreviewParam() {
      const hasCmsPreview = new URL(location.href).searchParams.get("cmsPreview");

      if (hasCmsPreview === "true") {
        this.$store.dispatch("resetStore");
      }
    },
    async checkLegalTexts() {
      window.CookieConsentApi?.hide();
      const languages = this.getCookieBannerLanguages();
      if (this.embeddedAutoAcceptConsent(languages)) {
        return;
      }
      removeEventListener("visitorCreated", this.checkLegalTexts);
      if (!this.user.userKey) {
        addEventListener("visitorCreated", this.checkLegalTexts);
        return;
      }
      EventBus.$off("clusterEstablishmentIdLoaded", this.checkLegalTexts);
      const urlObject = new URL(window.location.href);
      const code = urlObject.searchParams.get("id");
      if (!this.clusterEstablishmentId || this.$store.state.establishmentHash !== code) {
        EventBus.$on("clusterEstablishmentIdLoaded", this.checkLegalTexts);
        return;
      }
      const response = await this.$store.dispatch("getGDPRConfiguration");

      if (
        response?.cookiesPolicy?.active &&
        response?.cookiesPolicy?.bannerConfiguration?.firstLayerText &&
        response.cookiesPolicy.clauseId
      ) {
        const responseClause = await this.$store.dispatch("getLegalText", {
          id: response.cookiesPolicy.clauseId,
        });
        if (this.embeddedAutoAcceptConsent(languages)) {
          return;
        }
        if (responseClause) {
          languages.en.consent_modal.description =
            this.translate(response.cookiesPolicy.bannerConfiguration.firstLayerText) +
            '<button type="button" data-cc="c-settings" class="cc-link">' +
            this.getString("BANNER_DESCRIPTION_LINK") +
            "</button>";
          let buttonText = this.getString("COOKIES_POLICY");
          if (response.cookiesPolicy?.legalTextName) {
            buttonText = this.translate(response.cookiesPolicy.legalTextName);
          }
          languages.en.settings_modal.blocks[0].description =
            this.translate(response.cookiesPolicy.bannerConfiguration.secondLayerText) +
            '<button type="button" id="cookies_policy" data-cc="c-settings" class="cc-link">' +
            buttonText +
            "</button>";
          window.CookieConsentApi = window.initCookieConsent();
          window.CookieConsentApi.run({
            revision: responseClause.version,
            current_lang: "en",
            autoclear_cookies: true, // default: false
            page_scripts: true, // default: false
            force_consent: false, // default: false
            auto_language: "browser", // default: null; could also be 'browser' or 'document'
            cookie_expiration: 182, // default: 182 (days)

            onFirstAction: function (user_preferences, cookie) {},

            onAccept: function (cookie) {},

            onChange: function (cookie, changed_preferences) {
              window.location.reload();
            },

            languages: languages,
          });
          const policyButton = document.getElementById("cookies_policy");
          policyButton.addEventListener("click", () => {
            previewLegalClause(responseClause);
          });
        } else {
          this.autoAcceptConsent(languages);
        }
      } else {
        this.autoAcceptConsent(languages);
      }
    },
    embeddedAutoAcceptConsent(languages) {
      const currentQuery = this.$router.currentRoute?.value?.query;
      const token = currentQuery.token || currentQuery.urlToken;
      if (isEmbedPWA() || isEmbeddedPWAByAppId(config.appId) || isUrlTokenAppId(token)) {
        this.autoAcceptConsent(languages);
        return true;
      }
      return false;
    },
    autoAcceptConsent(languages) {
      window.CookieConsentApi = window.initCookieConsent();
      window.CookieConsentApi.run({
        revision: 0,
        current_lang: "en",
        autoclear_cookies: true, // default: false
        page_scripts: true, // default: false
        force_consent: false, // default: false
        auto_language: "browser", // default: null; could also be 'browser' or 'document'
        cookie_expiration: 182, // default: 182 (days)
        mode: "opt-out",
        onFirstAction: function (user_preferences, cookie) {},

        onAccept: function (cookie) {},

        onChange: function (cookie, changed_preferences) {},
        languages: languages,
      });
      setTimeout(() => {
        window.CookieConsentApi.hide();
        window.CookieConsentApi.accept(["neccessary", "analytics"]);
      }, 50);
    },
    getCookieBannerLanguages() {
      return {
        en: {
          consent_modal: {
            title: this.getString("COOKIE_BANNER_TITLE"),
            description:
              this.getString("BANNER_DESCRIPTION") +
              '<button type="button" data-cc="c-settings" class="cc-link">' +
              this.getString("BANNER_DESCRIPTION_LINK") +
              "</button>",
            primary_btn: {
              text: this.getString("COOKIE_BANNER_ACCEPT_ALL"),
              role: "accept_all", // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: this.getString("COOKIE_BANNER_REJECT_ALL"),
              role: "accept_necessary", // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: this.getString("COOKIE_PREFERENCES"),
            save_settings_btn: this.getString("COOKIE_BANNER_SAVE_SETTINGS"),
            accept_all_btn: this.getString("COOKIE_BANNER_ACCEPT_ALL"),
            reject_all_btn: this.getString("COOKIE_BANNER_REJECT_ALL"),
            close_btn_label: this.getString("CLOSE"),
            cookie_table_headers: [
              { col1: "Name" },
              { col2: "Domain" },
              { col3: "Expiration" },
              { col4: "Description" },
            ],
            blocks: [
              {
                title: this.getString("COOKIE_USAGE"),
                description:
                  this.getString("COOKIE_USAGE_DESCRIPTION") +
                  '.<br /><button type="button" data-cc="c-settings" class="cc-link">' +
                  this.getString("COOKIES_POLICY_LINK") +
                  "</button>",
              },
              {
                title: this.getString("COOKIE_BANNER_STRICTLY_NECESSARY"),
                description: this.getString("COOKIE_BANNER_STRICTLY_NECESSARY_DESCRIPTION"),
                toggle: {
                  value: "necessary",
                  enabled: true,
                  readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                },
              },
              {
                title: this.getString("COOKIE_BANNER_ANALYTICS_COOKIES"),
                description: this.getString("COOKIE_BANNER_ANALYTICS_COOKIES_DESCRIPTION"),
                toggle: {
                  value: "analytics", // your cookie category
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  // list of all expected cookies
                  {
                    col1: "session-id", // session cookie for Tinybird
                    col2: "tinybird.com",
                    col3: "30 minutes",
                    col4: this.getString("TINYBIRD_COOKIE_DESCRIPTION"),
                  },
                ],
              },
              {
                title: this.getString("MORE_INFO"),
                description: this.getString("COOKIES_MORE_INFORMATION"),
              },
            ],
          },
        },
      };
    },
    showCookieSettings() {
      window.CookieConsentApi.showSettings({});
    },
    goToLegalLinkUrl(clauseId) {
      this.loading = false;
      this.$store
        .dispatch("getLegalText", {
          id: clauseId,
        })
        .then((response) => {
          if (response) {
            previewLegalClause(response);
          }
        })
        .catch((error) => {
          this.showErrorMessage(this.getString("ERROR_PLEASE_TRY_AGAIN"));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    showErrorMessage(error) {
      console.error("Error", error);
    },
    setDisplaySurvey() {
      this.showDisplaySurveys = true;
    },
    checkForVerticalScreen() {
      if (!this.isLobby) {
        this.$store.dispatch("setVerticalScreen", false);
        return;
      }

      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;

      if ((this.lobbyHasTopAndBottomImages || this.lobbyHasVideo) && windowHeight > windowWidth) {
        this.$store.dispatch("setVerticalScreen", true);

        if (this.data.lobbyVideoURL && this.data.lobbyVideoURL !== "") {
          this.$store.dispatch("setVerticalScreenWithVideo", true);
        } else {
          this.$store.dispatch("setVerticalScreenWithVideo", false);
        }
      } else {
        this.$store.dispatch("setVerticalScreen", false);
      }
    },
    getVerticalLobbyImageURL(id) {
      return !id || this.verticalScreenWithVideo ? "" : `background-image: url(${getImageUrl(id, true)})`;
    },
    async getUserActivityAndInbox() {
      if (this.user.cliId) {
        this.$store.dispatch("getActivity");
      }
      if (!this.user?.userKey) {
        return;
      }
      const userInbox = await this.$store.dispatch("getUserInbox");
      await this.updateNotificationsSync(userInbox);
    },
    manageTransition(to, from) {
      if (to.path === "/" && from.path === "/") {
        this.enterActiveClass = "animated fadeIn";
      }
      if (this.continuedSwipe) {
        this.transitionAnimation = null;
      } else if (window.history.length - window.history.state.position >= 2 || window.history.state.forward) {
        this.transitionAnimation = "slide-right";
      } else {
        this.transitionAnimation = "slide-left";
      }
      this.startedSwipe = false;
      this.continuedSwipe = false;
      if (!this.transitionAnimation) {
        setTimeout(() => {
          this.transitionAnimation = "slide-left";
        }, 300);
      }
    },
    accessLinkUserCollision(user) {
      this.showError = true;
      this.errorMessage = this.getString("ACCESS_LINK_ALREADY_LOGGED").replace("%1", user.name);
    },
  },
};
</script>

<style>
@import "./assets/animate.css";

:root {
  --embed-top-safearea: 0px;
  --embed-bottom-safearea: 0px;
}
</style>
<style lang="less">
@import (reference) "./assets/lobby";
@import (reference) "./assets/styleguide";
html {
  height: 100%;
  font-size: 6px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  --main-color: @main-color;
}

body {
  margin: 0;
  height: 100%;
  overscroll-behavior-x: none;
}

#app {
  font-family: @font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  backface-visibility: hidden;
  color: #2c3e50;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.o-app-layout {
  height: 100%;
  width: 100%;
}

.o-router-view {
  &__content {
    height: 100%;
    position: relative;
  }
}

main {
  height: 100vh;
  background-color: white;
  @media @ipad-landscape, @ipad-portrait {
    height: 100%;
  }
}

header {
  margin: 0;
  height: 56px;
  padding: 0 16px 0 24px;
  background-color: #35495e;
  color: #ffffff;
}

header span {
  display: block;
  position: relative;
  font-size: 20px;
  line-height: 1;
  letter-spacing: 0.02em;
  font-weight: 400;
  box-sizing: border-box;
  padding-top: 16px;
}

.arrow-back {
  height: 4 * @base-unit;
  width: 4 * @base-unit;
  display: inline-block;
  z-index: 1001;
  &.alone {
    position: absolute;
    left: @margin-horizontal;
    top: @margin-minimum-unit*1.5;
  }
  img {
    // background-color: red;
    height: 24px;
    width: 24px;
    background-size: contain;
    background-position: center;
  }
}

.small-icon {
  height: 4.5em;
  width: 4.5em;
  display: inline-block;
  &.bookings {
    background: url("./assets/ic-reservas.png") no-repeat;
    background-size: contain;
  }
  &.requests {
    background: url("./assets/ic-peticiones.png") no-repeat;
    background-size: contain;
  }
  &.arrow-back {
    height: 24px;
    width: 24px;
    background: url("./assets/ic_arrow_black.png") no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 2000;
    &.hidden {
      opacity: 0;
    }
  }
  &.arrow-back-white {
    height: 4.5 * @base-unit;
    width: 4.5 * @base-unit;
    background: url("./assets/ic-arrow-white.png") no-repeat;
    background-size: contain;
    background-position: center;
  }
  &.inbox-icon {
    height: 4 * @base-unit;
    width: 4 * @base-unit;
    background: url("./assets/ic-notificaciones-black.png") no-repeat;
    background-size: contain;
    background-position: center;
  }
}

.panel-back {
  box-sizing: border-box;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: white;

  padding: calc(@size-base*6 + var(--embed-top-safearea)) @size-base*4 @size-base*2;

  &--vertical-creen {
    top: var(--lobby-vertical-img-height);
  }
  &--vertical-creen-video {
    top: var(--lobby-vertical-video-height);
  }
  .main-title {
    .u-text__headline--4;
    width: 84%;
    text-align: center;
    position: absolute;
    z-index: 1000;
    left: 8%;
  }

  .content-after-header {
    margin-top: 6.6 * @margin-minimum-unit;
    z-index: 900;
  }

  .main-title-desktop {
    .bodyFont;
    .semibold;
    width: 425px;
    text-align: center;
  }
  .inbox-icon {
    position: absolute;
    right: @margin-minimum-unit;
    z-index: 2000;
  }
  &.internal-web {
    z-index: 1002;
  }
}
.notifications {
  position: fixed;
  top: var(--embed-top-safearea);
  width: 100%;
  z-index: 10005;
  &.desktop {
    right: 0;
    width: 400px;
  }
}

.display-surveys {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  &.desktop {
    right: 0;
    width: 400px;
  }
}
.content-after-header {
  margin-top: 7.6 * @margin-minimum-unit;
  &.no-history {
    margin-top: @margin-minimum-unit;
  }
}
.redirect-body-app {
  position: fixed;
  top: 45vh;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header-app {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  font-family: "Open Sans", "-apple-system", "Helvetica Neue";
}
.loader-app {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 0.7s linear infinite; /* Safari */
  animation: spin 0.7s linear infinite;
  margin: auto;
}
.c--anim {
  z-index: 10000;
}
#c-p-bn,
#s-all-bn {
  .subheader-white !important;
  background: #8157cb;
}
#c-s-bn,
#c-p-bn {
  margin-top: 0 !important;
  border-radius: 30px !important;
  height: 32px !important;
  padding: 0 !important;
}
#s-all-bn,
#s-rall-bn,
#s-sv-bn {
  border-radius: 30px !important;
  height: 48px !important;
  padding: 0 !important;
}
#s-all-bn {
  .h4-white !important;
}
#s-rall-bn,
#s-sv-bn {
  .h4-black !important;
  color: #8157cb;
  background-color: white !important;
  border: 1px solid #34323d;
}
#c-s-bn {
  .subheader-gray !important;
  background-color: white !important;
  border: 1px solid #ebedf0 !important;
  margin-right: 24px !important;
}
#c-bns {
  flex-direction: row-reverse !important;
}
#s-bns {
  height: 200px !important;
}
.cc-link {
  .subheader-link !important;
  border-bottom: none !important;
  margin-top: 4px !important;
  margin-bottom: 16px !important;
}
.c_link {
  background-color: #e5ebef !important;
}
.cc_div .b-tg .c-tgl:checked ~ .c-tg {
  background: var(--cc-toggle-bg-on);
}
#s-bl .c-bl.b-ex,
#s-cnt .b-bn .b-tl:active,
#s-cnt .b-bn .b-tl:hover {
  background-color: #f7f8fa !important;
}
@media screen and (max-width: 688px) {
  #s-inr.bns-t {
    padding-bottom: 200px !important;
  }
}
#s-c-bn,
#s-c-bn:active,
#s-c-bn:link,
#s-c-bn:focus {
  background-color: white !important;
  border-radius: 12px !important;
  width: 24px !important;
  height: 24px !important;
  border: 1.8px solid black !important;
}
#s-c-bn:after,
#s-c-bn:before {
  top: 3px !important;
  left: 9px !important;
  width: 1.8px !important;
}
#s-bl .c-bl:first-child {
  margin-bottom: 2 * @base-unit !important;
}

.router-view-wrapper {
  height: 100%;
}

.router-view-wrapper.landscape {
  height: 100%;
}

.router-view-wrapper.vertical-screen {
  position: fixed;
  top: var(--lobby-vertical-img-height);
  left: 0;
  width: 100%;
  height: var(--lobby-vertical-content-height);
  overflow-y: scroll;
  z-index: 99999;

  &--video {
    top: var(--lobby-vertical-video-height);
  }
}

.vertical-screen-top-img,
.vertical-screen-bottom-img {
  position: fixed;
  left: 0;
  width: 100%;
  height: var(--lobby-vertical-img-height);
  background-image: url("https://images.unsplash.com/photo-1540541338287-41700207dee6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 10000;
}

.vertical-screen-top-img {
  top: 0;

  &--video {
    height: var(--lobby-vertical-video-height);
    iframe,
    video {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }
}

.vertical-screen-bottom-img {
  bottom: 0;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition:
    right 0.25s ease-out,
    left 0.25s ease-out,
    opacity 0.25s ease-out;
}

.slide-left-enter-to {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 1;
  width: 100%;
  overflow-x: hidden;
}

.slide-left-enter-from {
  position: absolute;
  right: -10px;
  top: 0;
  opacity: 0;
  width: 100%;
  overflow-x: hidden;
}

.slide-left-leave-to {
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
  width: 100%;
  overflow-x: hidden;
}

.slide-left-leave-from {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  width: 100%;
  overflow-x: hidden;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition:
    right 0.25s ease-out,
    left 0.25s ease-out,
    opacity 0.25s ease-out;
}

.slide-right-enter-to {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 1;
  width: 100%;
  overflow-x: hidden;
}

.slide-right-enter-from {
  position: absolute;
  left: -10px;
  top: 0;
  opacity: 0;
  width: 100%;
  overflow-x: hidden;
}

.slide-right-leave-to {
  position: absolute;
  right: -20px;
  top: 0;
  opacity: 0;
  width: 100%;
  overflow-x: hidden;
}

.slide-right-leave-from {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  overflow-x: hidden;
  opacity: 1;
}
</style>
