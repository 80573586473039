import {
  CHANGE_API_KEY,
  CHANGE_APP_ID,
  CHANGE_DEVICE_UID,
  CHANGE_ESTABLISHMENT,
  DEEPLINK_GOTOSECTION,
  FRONTPAGE_ICON,
  PRESTAY_CODE,
  SET_ESTABLISHMENT_HASH,
  SET_PUSH_TOKEN,
  UPDATE_MAIN_COLOR,
  UPDATE_USER,
  SET_EMBEDDED_TYPE,
} from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import {
  checkBearerExpired,
  checkBearerPreviousExpiration,
  getItemUrl,
  getServiceType,
  isLogged,
  isCorrectAppId,
  couldBeNativeEmbeddedPWA,
} from "@/services/utils";
import { EventBus } from "@/services/eventBus";
import config from "@/config";
import { jwtDecode as decode } from "jwt-decode";
import * as frontpage from "@/services/frontpage";
import spacesMixin from "../spacesMixin";
import pdfMixin from "../pdfMixin";
import cloneDeep from "lodash-es/cloneDeep";
import frontpageStyles from "@/utils/enums/frontpageStyles.js";

export default {
  mixins: [spacesMixin, pdfMixin],
  computed: {
    ...mapState("frontpage", ["data"]),
    ...mapState([
      "establishment",
      "user",
      "establishmentHash",
      "apiKey",
      "appId",
      "isDesktop",
      "isLobby",
      "isTV",
      "pendingTermsConditions",
    ]),
    params() {
      return this.$router.currentRoute.value.query;
    },
  },
  data() {
    return {
      createVisitorData: { locale: window.navigator.language.substring(0, 2) === "es" ? "es" : "en" },
      isLoaded: false,
      hasRef: false,
      accessGParam: false,
      accessKeyId: false,
      accessApiKey: false,
      catalogueService: null,
    };
  },
  watch: {
    "$route.query": {
      async handler(value) {
        if (!Object.keys(value).length) return;
        if (!["id", "ref"].some((i) => Object.keys(value).includes(i))) {
          return (this.isLoaded = true);
        }
        this.hasRef = this.hasRef ? this.hasRef : !!value.ref;
        await this.$_setupMethod();

        this.accessGParam = value.g;
        this.accessKeyId = value.k;
        this.accessApiKey = value.a;
        if (this.accessApiKey) {
          await this.getDataFromApiKey(this.accessApiKey);
        }
        if (this.accessGParam && this.accessKeyId && this.accessApiKey) {
          await this.loginWithAccessGParam(this.accessGParam, this.accessKeyId, this.accessApiKey);
        }
        const destination = await this.deepLinksRefactor(value);
        if (destination < 0 && !this.hasRef) {
          return destination === -2 ? (this.isLoaded = true) : null;
        }
        this.getFrontpageData();
        setTimeout(() => {
          this.isLoaded = true;
        }, 300);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      "createVisitor",
      "getDeepLinkData",
      "getEstablishment",
      "getStrings",
      "decodeEstablishmentHash",
      "setIsFromPWA",
      "getService",
      "getServiceCatalogues",
      "createGuestReferralWithPS",
      "createGuestWithAccessGParam",
      "setPendingTermsConditions",
      "createGuestReferral",
      "logout",
      "getUserInbox",
      "getAppDataByApiKey",
    ]),
    ...mapActions("catalogues", ["getServiceCataloguesV2"]),
    ...mapActions("frontpage", ["storeFrontpage"]),
    $_decodeTokenUrl({ token }) {
      if (!token) {
        return;
      }

      const paramsInToken = decode(token);
      if (paramsInToken?.data?.apiKey) {
        config.apiKey = paramsInToken.data.apiKey;
        config.baseApiKey = paramsInToken.data.apiKey;
        config.appId = paramsInToken.data.appId;
        config.baseAppId = paramsInToken.data.appId;
        this.$store.commit(CHANGE_API_KEY, paramsInToken.data.apiKey);
        this.$store.commit(CHANGE_APP_ID, paramsInToken.data.appId);
      }
      if (paramsInToken?.data?.uid) {
        this.$store.commit(CHANGE_DEVICE_UID, paramsInToken.data.uid);
      }
      if (paramsInToken?.data?.pushToken) {
        this.$store.commit(SET_PUSH_TOKEN, paramsInToken.data.pushToken);
      }
    },
    $_decodeResponseById({ establishment }, params) {
      if (!establishment) {
        return;
      }

      this.$store.commit(CHANGE_ESTABLISHMENT, {
        establishmentId: establishment,
      });
      this.$store.commit(SET_ESTABLISHMENT_HASH, params.id);

      this.$_decodeTokenUrl(params);
    },
    async $_setupMethod() {
      if (!this.params.id) return;

      if (this.apiKey?.length && this.appId > 0) {
        config.apiKey = this.apiKey;
        config.baseApiKey = this.apiKey;
        config.appId = this.appId;
        config.baseAppId = this.appId;
      }

      this.setIsFromPWA(true);

      let bearerExpired = false;
      if (config.cmsApiHostCredentials.bearer) {
        bearerExpired = checkBearerExpired(config.cmsApiHostCredentials.bearer);
        if (!bearerExpired && config.botApiCredentials.bearer) {
          bearerExpired = checkBearerExpired(config.botApiCredentials.bearer);
        }
        //we dont check access_token because its token is always bigger than the others
      } else {
        bearerExpired = true;
      }

      if (!(this.establishment?.id && this.establishmentHash === this.params.id && !bearerExpired)) {
        try {
          const response = await this.decodeEstablishmentHash({
            establishmentHash: this.params.id,
            essentialBearer: true,
          });
          this.$_decodeResponseById(response, this.params);
        } catch (e) {
          console.error(e);
        }
        return;
      }

      let halfDayExpired = false;
      if (config.cmsApiHostCredentials.bearer) {
        halfDayExpired = checkBearerPreviousExpiration(config.cmsApiHostCredentials.bearer);
        if (!halfDayExpired && config.botApiCredentials.bearer) {
          halfDayExpired = checkBearerPreviousExpiration(config.botApiCredentials.bearer);
        }
      }

      if (!halfDayExpired) {
        this.$_decodeTokenUrl(this.params);
        return;
      }

      setTimeout(async () => {
        try {
          const response = await this.decodeEstablishmentHash({
            establishmentHash: this.params.id,
            essentialBearer: false,
          });
          this.$_decodeResponseById(response, this.params);
        } catch (e) {
          console.error("decodeEstablishmentHash previous err", e);
        }
      }, 1300);
    },

    toCatalogue(catalogue) {
      this.$router.push({
        name: "ServiceCatalogueWithId",
        params: {
          catalogueId: catalogue.id,
          serviceType: this.catalogueService.typeString,
          serviceId: catalogue.service,
        },
        state: {
          params: cloneDeep({
            catalogue: catalogue,
            id: catalogue.service,
            serviceData: this.catalogueService,
            itemType: this.catalogueService.itemType ? this.catalogueService.itemType : 3,
          }),
        },
        query: { id: this.establishmentHash },
      });
    },
    openCatalogueURL(catalogue) {
      if (catalogue.type === "pdf" && catalogue.pdf) {
        this.openPdfMenuURL(catalogue.pdf, true);
      } else if (catalogue.type === "url" && catalogue.url) {
        this.openPdfMenuURL(catalogue.url, false);
      } else if (catalogue.type === "products") {
        this.toCatalogue(catalogue);
      }
    },
    async $_modifyRefUrl() {
      const params = this.$router.currentRoute.value.query;
      const paramsFilter = {};

      Object.keys(params).forEach((key) => {
        const value = params[key];
        if (key.indexOf("ref") === -1) {
          paramsFilter[key] = value;
        }
      });

      const paramsFilterUrl = new URLSearchParams(paramsFilter).toString() ?? "";

      if (paramsFilterUrl && paramsFilterUrl.length) {
        if (couldBeNativeEmbeddedPWA(config.appId)) {
          await this.$router.replace(`?${paramsFilterUrl}`);
        } else {
          await this.$router.push(`?${paramsFilterUrl}`);
        }
      }
    },
    $_extractMessageUtmReferral(ref) {
      const regexResult = ref && /UTM_([^_]+)/.exec(ref);
      return regexResult?.length > 1 ? regexResult[1] : false;
    },
    $_updateUser() {
      const { id } = this.establishment;
      const { room, cliId, name, checkIn, checkOut } = this.user.establishments?.[id];

      if (!room && !cliId) {
        return false;
      }

      const entries = Object.entries({
        name: name,
        room: room,
        checkIn: checkIn,
        checkOut: checkOut,
        cliId: cliId,
      }).filter(([key, value]) => value !== undefined);
      const data = Object.fromEntries(entries);

      this.$store.commit(UPDATE_USER, data);
      return true;
    },
    $_deleteUserKeyAndDeviceKey() {
      this.$store.commit(UPDATE_USER, {
        userKey: false,
        deviceKey: false,
      });
    },
    $_storeDeviceAndPushToken({ device, pushToken }) {
      if (device?.uid) {
        this.$store.commit(CHANGE_DEVICE_UID, device.uid);
      }
      if (pushToken) {
        this.$store.commit(SET_PUSH_TOKEN, pushToken);
      }
    },
    async $_getEstablishmentData() {
      try {
        await this.getEstablishment();
        this.getStrings();
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingEstablishment = false;
      }
    },
    $_resetURLWithParams() {
      const desktopURL = this.isDesktop ? "/desktop" : "";
      let urlReplace = desktopURL + "/?id=" + this.establishmentHash;

      const urlParams = new URLSearchParams();
      const paramsArray = ["utm_source", "utm_medium", "utm_campaign"];
      paramsArray?.forEach((param) => {
        if (this.$store.state[param]) {
          urlParams.append(param, this.$store.state[param]);
        }
      });

      const separator = urlParams?.size > 0 ? "&" : "";
      urlReplace = `${urlReplace}${separator}${urlParams?.toString()}`;
      this.$router.replace(urlReplace);
    },
    async $_deepLink(ref) {
      const idLink = ref.split("_")[1];
      const data = this.user;

      data.idLink = idLink;

      if (idLink?.indexOf("DL") !== -1) {
        return;
      }

      try {
        const response = await this.getDeepLinkData(data);
        const action = response.action;
        const type = response.type;

        if (action === "syncestablishment") {
          return setTimeout(() => this.$_modifyRefUrl(), 50);
        }

        if (action === "gotosection" && type === "checkIn") {
          const userData = this.user;
          userData.establishmentId = this.establishment.id;
          userData.locale = this.locale;
          userData.id = userData.uid;

          const url = getItemUrl(1, 0, 0, userData, 72);
          this.$router.push({
            path: `${url}?email=${response.email}&locator=${response.locator}`,
          });

          return;
        }

        if (action === "gotosection") {
          this.$store.commit(DEEPLINK_GOTOSECTION, true);

          const pdfItem = response.pdfId;
          const menuId = response.menuId;
          const catalogueId = response.catalogueId;
          const siteId = response.siteId;
          const cataloguePublicId = response.cataloguePublicId; //new catalogues
          const servicePublicId = response.servicePublicId;

          const pathLink = getItemUrl(
            response.itemType,
            response.itemId,
            response.serviceType,
            {},
            response.cmsModule,
            response.serviceId,
            this.isLobby,
            this.isDesktop,
            this.isTV,
          );

          await this.$_modifyRefUrl();

          const isNotMobile = this.isDesktop || this.isLobby || this.isTV;

          if (cataloguePublicId) {
            return this.cataloguesV2Deeplink(response, servicePublicId, isNotMobile);
          }

          if (pdfItem || menuId || catalogueId || siteId || cataloguePublicId) {
            if (pdfItem) {
              await this.$router.push({
                params: { pdfId: pdfItem },
                query: { id: this.establishmentHash },
                path: pathLink,
              });
            } else if (catalogueId) {
              await this.$router.push({
                params: { catalogueId: catalogueId },
                query: { id: this.establishmentHash },
                path: pathLink,
              });
            } else if (menuId) {
              await this.$router.push({
                params: { menuId: menuId },
                query: { id: this.establishmentHash },
                path: pathLink,
              });
            } else if (siteId) {
              await this.$router.push({
                params: { siteId: siteId },
                query: { siteId: siteId, id: this.establishmentHash },
                path: pathLink,
              });
            }

            this.getService({
              serviceType: response.serviceType,
              serviceId: response.serviceId,
            }).then((service) => {
              if (catalogueId) {
                this.catalogueService = service;
                this.getServiceCatalogues({
                  serviceId: response.serviceId,
                  serviceType: service.typeString,
                }).then((catalogues) => {
                  if (catalogues) {
                    if (service.typeString != "roomservicesV2") {
                      const selectedCatalogue = catalogues.find((element) => element.id === catalogueId);
                      if (selectedCatalogue) {
                        if (!this.isSpacesOrderCheck(service)) {
                          this.openCatalogueURL(selectedCatalogue);
                        } else {
                          this.$router.replace({
                            params: { catalogueId: catalogueId },
                            query: { spaceCatalogueId: catalogueId, id: this.establishmentHash },
                          });
                        }
                      }
                    }
                  }
                });
              } else if (service && service.menusOnPdf && service.menusOnPdf.length) {
                let menuById = undefined;
                service.menusOnPdf.forEach((menu) => {
                  let isThisMenu = undefined;
                  if (menuId) {
                    isThisMenu = this.checkPdfMenuByMenuId(menuId, menu);
                  } else if (pdfItem) {
                    isThisMenu = this.checkPdfMenu(pdfItem, menu);
                  }
                  if (isThisMenu) {
                    menuById = menu;
                  }
                });
                if (menuById) {
                  this.openPdfMenuURL(menuById, true);
                }
              }
            });

            return;
          }

          const cmsModule = [95, 70, 63, 97].includes(Number(response.cmsModule));

          if (cmsModule && isNotMobile) {
            EventBus.$emit("desktopDeeplinksListener", response);
            return;
          }

          if (response.cmsModule !== 97) {
            return await this.$router.push({
              path: pathLink,
              query: { id: this.establishmentHash },
            });
          }

          let args = {
            itemId: response.itemId,
            itemType: response.itemType,
            serviceType: response.serviceType,
          };

          if (response.itemId === 97 && response.serviceType === 47 && !response.serviceId) {
            args = {
              itemType: 15,
              itemId: this.establishment.id,
              title: this.getString("REQUEST_CHAT_TITLE"),
            };
          }

          EventBus.$emit("enquiriesButtonAction", args);
          return;
        }

        if (action === "autologin") {
          return;
        }
      } catch (err) {
        console.error("deeplink error", err);
      }
    },
    async cataloguesV2Deeplink(response, servicePublicId, isNotMobile) {
      const { cataloguePublicId, serviceType, catalogueType } = response;

      if (catalogueType === "manual") {
        const urlParams = {
          servicePublicId: servicePublicId,
          cataloguePublicId: cataloguePublicId,
          serviceType: getServiceType(serviceType),
        };
        const routeName = !isNotMobile ? "ServiceCatalogueV2WithId" : "ServiceCatalogueV2WithIdDesktop";
        await this.$router.push({
          name: routeName,
          params: urlParams,
          state: { params: urlParams },
          query: { id: this.establishmentHash },
        });
        return;
      }

      //url and pdf types
      try {
        const responseCatalogueV2 = await this.$store.dispatch("catalogues/getServiceCataloguesV2", {
          id: servicePublicId,
          catalogueId: cataloguePublicId,
        });

        if (responseCatalogueV2?.publicId) {
          if (catalogueType === "url") {
            this.openPdfMenuURL(responseCatalogueV2.url, false);
          } else if (catalogueType === "pdf") {
            this.openPdfMenuURL(responseCatalogueV2.pdf, true);
          }
          return;
        }
      } catch (error) {
        console.error("Error getting catalogues", error);
      }
      return;
    },
    async deepLinksRefactor({ ref, id }) {
      if (!ref) {
        return id ? -2 : -1;
      }

      const referralComponents = ref.split("_");
      if (referralComponents.length && referralComponents[0] === "UTM") {
        return;
      }
      const utm = this.$_extractMessageUtmReferral(ref);
      this.createVisitorData = utm ? { utm } : this.createVisitorData;
      await this.$_getEstablishmentData();

      if (ref.indexOf("_DL") !== -1) {
        try {
          await this.createVisitor(this.createVisitorData);
          this.$_updateUser();
          return await this.$_deepLink(ref);
        } catch (err) {
          console.error("Error creating visitor", err);
        }
      } else {
        await this.$_preStayRefactor(ref, utm);
      }
    },
    async getDataFromApiKey(apiKey) {
      if (!apiKey) {
        return;
      }
      try {
        const appData = await this.getAppDataByApiKey(apiKey);
        config.apiKey = apiKey;
        config.baseApiKey = apiKey;
        this.$store.commit(CHANGE_API_KEY, apiKey);
        if (appData?.id) {
          config.baseAppId = appData.id;
          config.appId = appData.id;
          this.$store.commit(CHANGE_APP_ID, appData.id);
        }
        if (appData?.embeddedType) {
          this.$store.commit(SET_EMBEDDED_TYPE, appData.embeddedType);
        }
      } catch (err) {
        console.error("Error gettin data from apiKey", err);
      }
    },
    async loginWithAccessGParam(gParam, keyId, apiKey) {
      if (!gParam || !keyId || !apiKey) {
        return;
      }
      await this.$_getEstablishmentData();
      const wasLoggedBeforeLogin = isLogged(this.user);
      try {
        await this.createVisitor();
        await this.createGuestWithAccessGParam({
          bookingPayload: gParam.replace(/ /g, "+"),
          keyId: keyId,
          apiKey: apiKey,
        });
        this.$_updateUser();
      } catch (err) {
        if (wasLoggedBeforeLogin) {
          EventBus.$emit("accessLinkUserCollision", this.user);
        }
        console.error("Error creating visitor", err);
      }
      this.$_resetURLWithParams();
    },
    async $_preStayRefactor(ref, utm) {
      if (!this.$store.state.preStayCode) {
        return -1;
      }
      //TODO: delete this if condition when we delete prestays in frontpagemixin.js -> if (this.data?.frontpageStyle != frontpageStyles.FRONTPAGE_STYLE_WIDGETS) {
      // } //we wont have here self.autoLoginWithReferralFailed("LOGIN_ERROR"); if !isLogged -> from frontpageMixin
      if (this.data?.frontpageStyle === frontpageStyles.FRONTPAGE_STYLE_WIDGETS) {
        await this.$_logoutUser();

        const data = { preStay: this.$store.state.preStayCode, utm: utm };
        await this.$_referralWithPreStayCode(data, ref, utm);
        this.$store.commit(PRESTAY_CODE, undefined);
        this.$_updateUser();

        this.setPendingTermsConditions({
          establishment: this.$store.state.establishment.id,
          value: isLogged(this.user),
        });

        this.$_resetURLWithParams();
        if (this.isLobby) {
          return;
        }
        // We need to get the user activity and inbox after the user is created.
        // This is read in App.vue
        EventBus.$emit("getUserActivityAndInbox");
      } else {
        return -1;
      }
    },
    async $_logoutUser() {
      if (!this.user?.userKey || !this.user?.deviceKey) {
        return;
      }
      try {
        await this.logout();
      } catch (err) {
        this.$_deleteUserKeyAndDeviceKey();
      }
    },
    async $_referralWithPreStayCode(data, ref, utm) {
      try {
        const responseReferalWithPS = await this.createGuestReferralWithPS(data);
        if (!responseReferalWithPS?.establishments) {
          return;
        }
        this.$_storeDeviceAndPushToken(responseReferalWithPS);
      } catch (err) {
        // if prestay guest creation fails due to old link or not being PMS link, we create the guest with the old autologin api call
        await this.$_referralWithAutologin(ref, utm);
      }
    },
    async $_referralWithAutologin(ref, utm) {
      try {
        await this.createVisitor(this.createVisitorData);
        const data = { linkId: ref.split("_").length > 1 ? parseInt(ref.split("_")[1]) : ref, utm: utm };
        await this.createGuestReferral(data);
      } catch (err) {
        console.error("Error creating visitor", err);
      }
    },
    async getFrontpageData() {
      if (!isCorrectAppId()) {
        return;
      }
      await frontpage.get(
        this.$store.state,
        ({ data }) => {
          if (!data) {
            return;
          }

          this.storeFrontpage(data);

          const frontpageIcon = data.navBarTransparent ?? data.headerWidget?.frontpageIcon;

          this.$store.commit(UPDATE_MAIN_COLOR, data.mainColor ?? "ffffff");
          this.$store.commit(FRONTPAGE_ICON, frontpageIcon ?? 0);

          const html = document.querySelector("html").style;
          html.setProperty("--main-color", this.$store.state.mainColor);
          html.setProperty("--text-color", data.textColor ?? "#34323D");
          html.setProperty("--cc-btn-primary-bg", this.$store.state.mainColor);
        },
        console.error,
      );
    },
  },
};
